<template>
    <template-base width="95">
        <h1 class="card-header">Digite sua nova senha</h1>
        <div class="card-body">
            <form @submit.prevent="reset">
                <div class="form-group">
                    <label for="userPassword">Senha</label>
                    <input class="form-control tcs-input" id="userPassword" v-model="password" type="password" required
                        :disabled="isBusy"
                        @focus="infoSenha=true"
                        @blur="infoSenha=false"
                        pattern="(((?=.*\d)(?=.*[a-z])(?=.*[A-Z]))|((?=.*\d)(?=.*[a-z])(?=.*[^\s\w\d]))|((?=.*\d)(?=.*[^\s\d\w])(?=.*[A-Z]))|((?=.*[^\s\d\w])(?=.*[a-z])(?=.*[A-Z]))).{8,}"
                        />
                </div>
                <div class="mb-2" v-show="infoSenha">
                        <div>A senha precisa conter no mínimo 8 caracteres e pelo menos um caractere de 3 das 4 categorias a seguir:</div>
                        <span :class="{ 'text-success': /[a-z]/.test(password) }">letra minúscula</span>,
                        <span :class="{ 'text-success': /[A-Z]/.test(password) }">letra maiúscula</span>,
                        <span :class="{ 'text-success': /[0-9]/.test(password) }">número</span>,
                        <span :class="{ 'text-success': /[^\s\d\w]/.test(password) }">caractere especial</span>
                </div>
                <div class="form-group">
                    <label for="userPassword2">Confirme a senha</label>
                    <input class="form-control tcs-input" id="userPassword2" v-model="password2" type="password" required
                        :disabled="isBusy" />
                </div>
                <vue-recaptcha v-if="captcha" sitekey="6LcQWL8dAAAAAMS38bZsYq0JYTQSoHxm5wkqUR0i" @verify="verifica" ref="recaptcha" :loadRecaptchaScript="true"></vue-recaptcha>
                <div class="d-flex justify-content-between">
                    <router-link replace to="/" class="align-self-center" data-cy="Cancelar">Cancelar</router-link>
                    <button type="submit" class="btn btn-success lg" v-if="!isBusy">
                        Alterar senha
                    </button>
                    <button type="submit" class="btn btn-success lg" disabled="disabled" v-else>
                        <span class="spinner-border x-spinner-border-sm" role="status" aria-hidden="true"></span>
                        &nbsp;Alterando senha...
                    </button>
                </div>
                <transition name="fade">
                    <div class="mt-1 d-flex text-danger" v-if="error">
                        <x-circle-icon class="mr-2" />
                        <div>{{error}}</div>
                    </div>
                    <div class="mt-1 d-flex text-success" v-else-if="success">
                        <check-circle-icon class="mr-2" />
                        <div>Sucesso! Redirecionando...</div>
                    </div>
                </transition>
            </form>
        </div>
    </template-base>
</template>
<script>
import TemplateBase from '../templates/Base'
import loginService from '../services/login';
import { wait } from '../helpers/common';
import api from '@/api';
import axios from 'axios';
import VueRecaptcha from 'vue-recaptcha';

export default {
    components: {
        TemplateBase,
        VueRecaptcha,
    },
    data () {
        return {
            password: '',
            password2: '',
            isBusy: false,
            error: '',
            success: false,
            infoSenha: false,
            reCaptchaResponse: '',
            captcha: process.env.NODE_ENV === 'production',
        }
    },
    created() {
        if (loginService.isLoggedIn()) {
            this.$router.replace('/');
        } else if (this.$route.params.token) {
            this.$router.replace({ name: 'redefinir_senha', hash: '#!' + this.$route.params.token });
        } else if (!document.location.hash.startsWith("#!")) {
            this.$router.replace({ name: 'esqueci_senha' });
        }
    },
    methods: {
        verifica(token) {
            this.reCaptchaResponse = token;
        },
        async reset () {
            this.isBusy = true;
            this.error = '';
            this.success = false;

            try {
                if (!this.reCaptchaResponse && this.captcha) throw new Error('Por favor preencha o captcha.');

                if (this.password !== this.password2) throw new Error('Senhas não coincidem');
                let res = await axios.put(api.v1.password.reset, {
                    token: document.location.hash.substr(2),
                    senha: this.password,
                });

                if (!res.data.error) {
                    this.success = true;
                    await wait(1000);
                    await loginService.login(res.data.email, this.password, this.reCaptchaResponse);
                    this.$router.replace('/');
                    this.isBusy = false;
                } else {
                    throw new Error(res.data.error);
                }
            } catch (e) {
                this.$refs.recaptcha.reset();
                this.reCaptchaResponse = '';
                this.isBusy = false;
                this.error = e.response?.data?.error || e.toString() || 'Erro desconhecido, por favor tente novamente.';
            }
        },
    },
};
</script>
<style scoped>
.spinner-border.x-spinner-border-sm {
    width: 1.1rem;
    height: 1.1rem;
    border-width: 0.2em;
}

@media screen and (min-width: 790px) {
    .card {
        max-width: 55%
    }
}
</style>
